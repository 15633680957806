import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import NavbarCp from "../../components/NavbarCp";
import FooterCp from "../../components/FooterCp";
import { DetailInvoice } from "../../services";
import { Container, Button, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import swal from "sweetalert";
import moment from "moment";
// import check from "../../assets/img/check.png";
import { FormatRupiah } from "@arismun/format-rupiah";
import { BsFillCheckCircleFill, BsFillXOctagonFill, BsFillClockFill } from "react-icons/bs";

const Topup = () => {
  const [invoiceDetail, setInvoiceDetail] = useState(null);
  const [title, setTitle] = useState("");
  const [site, setSite] = useState([]);
  const [searchParams] = useSearchParams();
  const reff = searchParams.get("merchantOrderId");

  const detailSite = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/data/setting`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + process.env.REACT_APP_TOKEN,
        },
      });
      const data = await response.json();
      setSite(data.data[0]);
      setTitle(data.data[0].title);
    } catch (err) {
      swal(err.message, {
        icon: "error",
        button: true,
      });
    }
  };

  useEffect(() => {
    const fetchInvoiceDetail = async () => {
      try {
        const detail = await DetailInvoice(reff);
        setInvoiceDetail(detail);
      } catch (error) {
        swal(error.message, {
          icon: "error",
          button: true,
        });
      }
    };
    window.scrollTo(0, 0);
    detailSite();
    fetchInvoiceDetail();
  }, [reff]);

  return (
    <HelmetProvider>
      <Helmet prioritizeSeoTags>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta content="yes" name="apple-mobile-web-app-capable" />
        <meta name="viewport" content="width=device-width, minimum-scale=1, maximum-scale=1, initial-scale=1, user-scalable=no" />
        <title>{title} - Website topup termurah dan terbaik</title>
        <meta name="application-name" content={title} />
        <meta name="copyright" content={`© 2023 by ${title}`} />
        <meta name="description" content={`Top up or buy game credits/diamonds/game voucher, fast and cheap at ${title}. ${title} is the best way to buy game credits/game vouchers.`} />
        <meta name="title" content={`${title} - Website topup termurah dan terbaik`} />
        <meta name="robots" content="noodp, noydir" />
        <meta name="theme-color" content="#ff962d" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`${title} ${title} - Cheapest and Fastest Online Game Voucher`} />
        <meta property="og:description" content={`Top up or buy game credits/diamonds/game voucher, fast and cheap at ${title}. ${title} is the best way to buy game credits/game vouchers.`} />
        <meta property="twitter:card" content="summary" />
        <meta property="twitter:site" content={`@${title.toLowerCase()}`} />
        <meta property="twitter:title" content={`${title} ${title} - Cheapest and Fastest Online Game Voucher`} />
        <meta property="twitter:description" content={`Top up or buy game credits/diamonds/game voucher, fast and cheap at ${title}. ${title} is the best way to buy game credits/game vouchers.`} />
      </Helmet>
      <div className="set-height">
        <NavbarCp data={site} />
        <Container>
          <Row className="style-area my-3 d-flex justify-content-center">
            {invoiceDetail &&
              invoiceDetail.map((item, index) => (
                <Col md={8} className="my-3" key={index}>
                  {item.status === "SUCCESS" ? (
                    <BsFillCheckCircleFill className=" mb-4 text-success check-icon mx-auto d-flex justify-content-center" />
                  ) : item.status === "PENDING" ? (
                    <BsFillClockFill className=" mb-4 text-warning check-icon mx-auto d-flex justify-content-center" />
                  ) : (
                    <BsFillXOctagonFill className=" mb-4 text-danger check-icon mx-auto d-flex justify-content-center" />
                  )}

                  {item.status === "SUCCESS" ? (
                    <h4 className="mx-auto d-flex justify-content-center">Pembelian Berhasil</h4>
                  ) : item.status === "PENDING" ? (
                    <h4 className="mx-auto d-flex justify-content-center">Pembelian Sedang Di Proses</h4>
                  ) : (
                    <h4 className="text-danger mx-auto d-flex justify-content-center">Pembelian Gagal</h4>
                  )}
                  <h6 className="mb-4">Detail Transaksi</h6>
                  <Row className="my-2">
                    <Col xs={5}>
                      <span>Ref Transaksi</span>
                    </Col>
                    <Col xs={1}>
                      <span>:</span>
                    </Col>
                    <Col xs={5}>
                      <span>{item.ref}</span>
                    </Col>
                    <Col xs={5}>
                      <span>Username</span>
                    </Col>
                    <Col xs={1}>
                      <span>:</span>
                    </Col>
                    <Col xs={5}>
                      <span>{item.customer.username}</span>
                    </Col>
                    <Col xs={5}>
                      <span>ID</span>
                    </Col>
                    <Col xs={1}>
                      <span>:</span>
                    </Col>
                    <Col xs={5}>
                      <span>{item.customer.id}</span>
                    </Col>
                    <Col xs={5}>
                      <span>Item</span>
                    </Col>
                    <Col xs={1}>
                      <span>:</span>
                    </Col>
                    <Col xs={5}>
                      <span>{item.product.name}</span>
                    </Col>
                    <Col xs={5}>
                      <span>Product</span>
                    </Col>
                    <Col xs={1}>
                      <span>:</span>
                    </Col>
                    <Col xs={5}>
                      <span>{item.product.game.name}</span>
                    </Col>
                    <Col xs={5}>
                      <span>Metode pembayaran</span>
                    </Col>
                    <Col xs={1}>
                      <span>:</span>
                    </Col>
                    <Col xs={5}>
                      <span>{item.payment.name}</span>
                    </Col>
                    <Col xs={5}>
                      <span>Jumlah</span>
                    </Col>
                    <Col xs={1}>
                      <span>:</span>
                    </Col>
                    <Col xs={5}>
                      <span>
                        {" "}
                        <FormatRupiah value={item.total_payment} />
                      </span>
                    </Col>
                    <Col xs={5}>
                      <span>Tanggal Order</span>
                    </Col>
                    <Col xs={1}>
                      <span>:</span>
                    </Col>
                    <Col xs={5}>
                      <span>{moment(item.createdAt).format("DD MMMM YYYY, HH:mm:ss")}</span>
                    </Col>
                    <Col xs={5}>
                      <span>Status</span>
                    </Col>
                    <Col xs={1}>
                      <span>:</span>
                    </Col>
                    <Col xs={5}>
                      <span>{item.status}</span>
                    </Col>
                    <div className="d-flex align-items-center mx-auto d-flex justify-content-center gap-2 my-5">
                      <Link to="/">
                        <Button className="btn-purple-outline fz-14 px-md-4" variant="">
                          Lakukan pembelian lain
                        </Button>
                      </Link>
                      <Link to="/cek-pesanan">
                        <Button className="fz-14 px-md-4" variant="outline-secondary">
                          Cek riwayat transaksi
                        </Button>
                      </Link>
                    </div>
                  </Row>
                </Col>
              ))}
          </Row>
        </Container>
        <FooterCp data={site} />
      </div>
    </HelmetProvider>
  );
};

export default Topup;
